/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "no-stacking": "",
        size: "md"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "mb-4", attrs: { "align-h": "between" } },
            [
              _c("b-col", { attrs: { cols: "10" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/icon_logo_violet.svg"),
                    alt: ""
                  }
                })
              ]),
              _c("b-col", { staticClass: "text-right" }, [
                _vm.bottomRow != "processing"
                  ? _c(
                      "a",
                      {
                        staticClass: "tw-text-eclipse",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            _vm.showDialog = false
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "x-circle", "font-scale": "1.5" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v("Add Shipping Charge")
              ]),
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", [
                          _vm._v(
                            " Charges the patient for special shipping conditions. This only charges them, the modifications to their orders currently must be made manually. "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "span",
                            { staticStyle: { "letter-spacing": "2px" } },
                            [_c("b", [_vm._v("CHARGE")])]
                          ),
                          _c("br"),
                          _c("b-form-select", {
                            attrs: { options: _vm.chargeOptions },
                            model: {
                              value: _vm.selectedCharge,
                              callback: function($$v) {
                                _vm.selectedCharge = $$v
                              },
                              expression: "selectedCharge"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _c("b-col", [
                        _c(
                          "span",
                          { staticStyle: { "letter-spacing": "2px" } },
                          [_c("b", [_vm._v("AMOUNT")])]
                        ),
                        _c("br"),
                        _vm.selectedCharge
                          ? _c("span", [
                              _c("b", [
                                _vm._v(
                                  "$" +
                                    _vm._s(_vm.chargeValues[_vm.selectedCharge])
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4" },
                    [
                      _vm.alert.show
                        ? _c(
                            "b-col",
                            { staticClass: "text-center p-4 bg-light" },
                            [
                              _c(
                                "b",
                                { staticStyle: { "letter-spacing": "2px" } },
                                [_vm._v(_vm._s(_vm.alert.message))]
                              ),
                              _c("br"),
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre" } },
                                [_vm._v(_vm._s(_vm.alert.submessage))]
                              )
                            ]
                          )
                        : _c("b-col", { staticClass: "p-4 my-3" })
                    ],
                    1
                  ),
                  _vm.bottomRow == "processing"
                    ? _c(
                        "b-row",
                        { staticClass: "mt-4 justify-content-center" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-3", attrs: { cols: "6" } },
                            [
                              _c("b-spinner", { attrs: { small: "" } }),
                              _c(
                                "span",
                                {
                                  staticClass: "pl-3",
                                  staticStyle: { "letter-spacing": "2px" }
                                },
                                [_vm._v("PROCESSING")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.bottomRow == "closeOnly"
                    ? _c(
                        "b-row",
                        { staticClass: "mt-4 justify-content-center" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "outline-primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.showDialog = false
                                    }
                                  }
                                },
                                [_vm._v(" Close ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c("b-col", { attrs: { cols: "4" } }),
                          _c(
                            "b-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "outline-dark"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.showDialog = false
                                    }
                                  }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "secondary"
                                  },
                                  on: { click: _vm.applyOvernightShipping }
                                },
                                [_vm._v(" Charge ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }